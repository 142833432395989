import { authFetch } from '../utils/authFetch';
const isDebug = false;

export const API_CONFIG = {
  BASE_URL: isDebug ? 'https://clara-ai-test-app-24wm3.ondigitalocean.app' : 'https://clara-ai-server-snjz7.ondigitalocean.app',
  TIMEOUT: 60 * 60 * 1000, // 30 minutes
  SSE_TIMEOUT: 60 * 60 * 1000 // 10 minutes
};

export const ProcessingStatus = {
  IDLE: 'IDLE',
  UPLOADING: 'UPLOADING',
  PROCESSING: 'PROCESSING',
  COMPLETE: 'COMPLETE',
  ERROR: 'ERROR'
};

// Processing state management
class ProcessingState {
  constructor() {
    this.listeners = new Set();
  }

  subscribe(listener) {
    this.listeners.add(listener);
    return () => this.listeners.delete(listener);
  }

  emit(status) {
    this.listeners.forEach(listener => listener(status));
  }
}

export const processingState = new ProcessingState();

const updateStatus = (status) => {
  processingState.emit(status);
};

// Server health check
export const checkServerStatus = async () => {
  try {
    const response = await fetch(`${API_CONFIG.BASE_URL}/health`);
    return response.status === 200;
  } catch (error) {
    console.error('Server health check failed:', error);
    return false;
  }
};

// Authentication endpoints
export const login = async (username, password) => {
  try {
    const response = await fetch(`${API_CONFIG.BASE_URL}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });
    
    if (!response.ok) {
      throw new Error('Login failed');
    }

    const data = await response.json();
    localStorage.setItem('claraJwtToken', data.token);
    return data.token;
  } catch (error) {
    throw new Error(error.message || 'Login failed');
  }
};

export const validateToken = async (token) => {
  try {
    const response = await authFetch(`${API_CONFIG.BASE_URL}/validate-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token }),
    });
    
    const data = await response.json();
    return data.valid;
  } catch (error) {
    return false;
  }
};

export const logout = () => {
  localStorage.removeItem('claraJwtToken');
  window.location.href = '/login';
};

// Main file processing function
export const transcribeAndTranslateFile = async (file) => {
  let xhr = null;
  let eventSource = null;
  let timeoutId = null;
  let jobId = null;

  try {
    // Step 1: Get pre-signed URL
    updateStatus({
      status: ProcessingStatus.UPLOADING,
      message: 'Requesting upload URL...',
      progress: 10
    });

    const presignedUrlResponse = await authFetch(`${API_CONFIG.BASE_URL}/generate-presigned-url`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        fileName: file.name,
        fileType: file.type
      }),
    });

    const responseData = await presignedUrlResponse.json();
    const { uploadUrl, key } = responseData;
    jobId = key.split('/')[1]; // Extract jobId from the key (jobs/jobId/filename)

    // Use jobId consistently for all status updates
    const updateWithId = (status) => {
      updateStatus({
        ...status,
        uploadId: jobId
      });
    };

    // Step 2: Upload to CDN using XHR
    updateWithId({
      status: ProcessingStatus.UPLOADING,
      message: 'Uploading file to storage...',
      progress: 30
    });

    await new Promise((resolve, reject) => {
      xhr = new XMLHttpRequest();
      
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentCompleted = Math.round((event.loaded * 50) / event.total);
          updateWithId({
            status: ProcessingStatus.UPLOADING,
            message: 'Uploading file...',
            progress: 30 + percentCompleted
          });
        }
      };

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.response);
        } else {
          reject(new Error(`Upload failed with status ${xhr.status}`));
        }
      };

      xhr.onerror = () => {
        console.error('XHR Error:', xhr.statusText);
        reject(new Error(`Upload failed: ${xhr.statusText}`));
      };
      xhr.ontimeout = () => reject(new Error('Upload timed out'));
      xhr.onabort = () => reject(new Error('Upload aborted'));

      xhr.open('PUT', uploadUrl, true);
      xhr.timeout = API_CONFIG.TIMEOUT;
      xhr.setRequestHeader('Content-Type', file.type);
      xhr.withCredentials = false; // Important for CORS with presigned URLs
      
      xhr.send(file);
    });

    // Step 3: Set up SSE with auth
    updateWithId({
      status: ProcessingStatus.PROCESSING,
      message: 'Processing file...',
      progress: 80
    });

    const token = localStorage.getItem('claraJwtToken');
    const eventSourceUrl = new URL(`${API_CONFIG.BASE_URL}/status/${jobId}`);
    eventSourceUrl.searchParams.append('token', token);
    eventSource = new EventSource(eventSourceUrl.toString());
    
    let processResolve;
    let processReject;
    const processPromise = new Promise((resolve, reject) => {
      processResolve = resolve;
      processReject = reject;
    });

    // Set timeout for SSE
    timeoutId = setTimeout(() => {
      if (eventSource) {
        eventSource.close();
        processReject(new Error('Processing timed out'));
      }
    }, API_CONFIG.SSE_TIMEOUT);

    eventSource.onmessage = (event) => {
      const status = JSON.parse(event.data);
      updateWithId({
        status: status.phase,
        message: status.message,
        progress: status.progress
      });

      if (status.phase === 'COMPLETE' || status.phase === 'ERROR') {
        clearTimeout(timeoutId);
        eventSource.close();
        if (status.phase === 'COMPLETE') {
          processResolve(status);
        } else {
          processReject(new Error(status.message));
        }
      }
    };

    eventSource.onerror = (error) => {
      clearTimeout(timeoutId);
      eventSource.close();
      processReject(error);
    };

    // Step 4: Trigger processing
    const processResponse = await authFetch(`${API_CONFIG.BASE_URL}/process-upload`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fileKey: key }),
    });

    const processData = await processResponse.json();

    // Wait for SSE to complete
    await processPromise;

    updateWithId({
      status: ProcessingStatus.COMPLETE,
      message: 'Processing complete',
      progress: 100
    });

    return {
      ...processData,
      jobId
    };

  } catch (error) {
    console.error('Processing error:', error);
    
    // Clean up resources
    if (timeoutId) clearTimeout(timeoutId);
    if (eventSource) eventSource.close();
    
    updateStatus({
      status: ProcessingStatus.ERROR,
      message: error.message,
      progress: 0,
      uploadId: jobId
    });
    
    throw error;
  }
};

// Upload control function
export const cancelUpload = (xhr) => {
  if (xhr && xhr.readyState !== 4) {
    xhr.abort();
  }
};

export default {
  checkServerStatus,
  login,
  logout,
  validateToken,
  transcribeAndTranslateFile,
  cancelUpload,
  ProcessingStatus,
  processingState
};